import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b524ddd0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CMenuDivider: require('@chakra-ui/vue').CMenuDivider, CSelect: require('@chakra-ui/vue').CSelect})
