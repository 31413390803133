var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "position": "relative",
      "width": "100%",
      "height": ['40px', '50px'],
      "overflow-x": "hidden"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": _vm.justifyContent,
      "grid-gap": ['4px', '8px'],
      "h": "auto",
      "padding-inline": ['4px', '8px'],
      "padding-block": "4px",
      "position": "absolute"
    }
  }, _vm._l(_vm.options, function (option) {
    return _c('c-button', {
      key: option.id,
      attrs: {
        "type": "button",
        "h": ['30px', '40px'],
        "px": ['8px', '16px'],
        "min-w": _vm.minWidth,
        "m": "0",
        "font-size": ['12px', '16px'],
        "line-height": "24px",
        "rounded": "full",
        "color": "primary.400",
        "bg": 'white',
        "border-width": "1px",
        "border-style": "solid",
        "border-color": "primary.400",
        "white-space": "nowrap",
        "font-weight": "400",
        "flex-shrink": "0"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return _vm.onChangeFilter(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option === null || option === void 0 ? void 0 : option.label) + " "), _c('Icon', {
      staticStyle: {
        "margin-left": "6px"
      },
      attrs: {
        "icon": "ic:sharp-close",
        "height": "25",
        "width": "25"
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }