import { render, staticRenderFns } from "./filter-button.vue?vue&type=template&id=4573d2e0&scoped=true&"
import script from "./filter-button.vue?vue&type=script&lang=js&"
export * from "./filter-button.vue?vue&type=script&lang=js&"
import style0 from "./filter-button.vue?vue&type=style&index=0&id=4573d2e0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4573d2e0",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CButton: require('@chakra-ui/vue').CButton, CText: require('@chakra-ui/vue').CText, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CBox: require('@chakra-ui/vue').CBox, CCheckbox: require('@chakra-ui/vue').CCheckbox, CSelect: require('@chakra-ui/vue').CSelect, CInputLeftAddon: require('@chakra-ui/vue').CInputLeftAddon, CInputGroup: require('@chakra-ui/vue').CInputGroup})
