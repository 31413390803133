<template>
  <c-box>
    <c-button
      background-color="white"
      color="gray.900"
      p="0"
      @click="$emit('open', true)"
    >
      <c-flex
        color="#008C81"
        scale-x="-1"
      >
        <Icon
          icon="oui:filter"
          height="22"
          width="22"
          style="transform: scaleX(-1)"
        />
      </c-flex>
    </c-button>

    <BaseModal
      :is-open="isOpen"
      :close-on-overlay-click="false"
      size="850px"
      overflow="visible"
      :with-button-close="true"
      @close="$emit('close', false)"
    >
      <template #header>
        <c-flex
          gap="8px"
          align-items="center"
          padding="16px 20px"
        >
          Filter
          <c-button
            type="button"
            :h="['24px', '34px']"
            :px="['4px', '8px']"
            m="0"
            :font-size="['12px', '16px']"
            line-height="24px"
            rounded="full"
            color="primary.400"
            :bg=" 'white'"
            border-width="2px"
            border-style="solid"
            border-color="primary.400"
            white-space="nowrap"
            font-weight="500"
            @click="reset"
          >
            <Icon
              icon="ic:baseline-restore"
              height="25"
              width="25"
              style="margin-right: 6px"
            />
            Reset
          </c-button>
        </c-flex>
      </template>
      <template #body>
        <c-flex
          width="100%"
          flex-direction="column"
          padding-inline="16px"
          gap="12px"
        >
          <c-box>
            <c-text>
              Status
            </c-text>
            <c-radio-group
              v-model="data.status"
              variant-color="primary"
              size="lg"
              :font-size="['14px', '16px']"
              spacing="6"
              is-inline
              class="tes"
            >
              <c-radio
                height="30px"
                value="all"
              >
                Semua
              </c-radio>
              <c-radio
                height="30px"
                value="active"
              >
                Aktif
              </c-radio>
              <c-radio
                height="30px"
                value="pending"
              >
                Pending
              </c-radio>
              <c-radio
                height="30px"
                value="non_active"
              >
                Tidak Aktif
              </c-radio>
            </c-radio-group>
          </c-box>

          <c-box>
            <c-text>
              Pengguna
            </c-text>
            <c-checkbox
              size="lg"
              variant-color="primary"
              class="tes2"
              v-model="data.nutritionist"
            >
              Ahli Gizi
            </c-checkbox>
          </c-box>

          <c-box width="100%">
            <c-text>
              Tanggal
            </c-text>
            <c-flex
              gap="8px"
              margin-top="8px"
            >
              <c-select
                v-model="data.date"
                placeholder="Pilih date"
                min-width="156px"
                max-width="156px"
                class="tes3"
                :is-invalid="!!validations?.error?.format()?.date"
              >
                <option value="startAt">
                  Start date
                </option>
                <option value="endAt">
                  End date
                </option>
              </c-select>

              <c-input-group width="100%">
                <c-input-left-addon
                  padding-inline="8px"
                  height="62px"
                  border-bottom-left-radius="8px"
                  border-top-left-radius="8px"
                  :border-color="!data.isMultipleDate && data.dateObject.start ? '#008C81' : !!validations?.error?.format()?.dateObject?.start ? 'red' : '#888888'"
                  border-width="2px"
                  background-color="neutral.superLightGray"
                  :_hover="{
                    borderColor: !data.isMultipleDate && data.dateObject.start ? '#008C81' : !!validations?.error?.format()?.dateObject?.start ? 'red' : '#888888',
                    borderRightColor: 'inherit',
                  }"
                >
                  <Icon
                    icon="material-symbols:calendar-today"
                    :color="!data.isMultipleDate && data.dateObject.start ? '#008C81' : !!validations?.error?.format()?.dateObject?.start ? 'red' : '#888888'"
                    height="22"
                    width="22"
                  />
                </c-input-left-addon>
                <DatePicker
                  v-model="data.dateObject.start"
                  placeholder="Select datetime"
                  value-type="date"
                  class="tes4-2"
                  :disabled="data.isMultipleDate"
                  :class="{
                    'active': !data.isMultipleDate && !!data.dateObject.start,
                    'invalid': !!validations?.error?.format()?.dateObject?.start
                  }"
                />
              </c-input-group>

              <c-input-group width="100%">
                <c-input-left-addon
                  padding-inline="8px"
                  height="62px"
                  border-bottom-left-radius="8px"
                  border-top-left-radius="8px"
                  :border-color="!data.isMultipleDate && data.dateObject.end ? '#008C81' : !!validations?.error?.format()?.dateObject?.end ? 'red' : '#888888'"
                  border-width="2px"
                  background-color="neutral.superLightGray"
                  :_hover="{
                    borderColor: !data.isMultipleDate && data.dateObject.end ? '#008C81' : !!validations?.error?.format()?.dateObject?.end ? 'red' : '#888888',
                    borderRightColor: 'inherit',
                  }"
                >
                  <Icon
                    icon="material-symbols:date-range"
                    :color="!data.isMultipleDate && data.dateObject.end ? '#008C81' : !!validations?.error?.format()?.dateObject?.end ? 'red' : '#888888'"
                    height="22"
                    width="22"
                  />
                </c-input-left-addon>
                <DatePicker
                  @input="onDayClick"
                  v-model="data.dateObject.end"
                  placeholder="Select datetime"
                  value-type="date"
                  class="tes4-2"
                  :disabled="data.isMultipleDate"
                  :class="{
                    'active': !data.isMultipleDate && !!data.dateObject.end,
                    'invalid': !!validations?.error?.format()?.dateObject?.end
                  }"
                />
              </c-input-group>
            </c-flex>
            <c-flex
              v-if="!!data.date"
              gap="16px"
              align-items="center"
              margin-top="8px"
            >
              <c-checkbox
                size="lg"
                variant-color="primary"
                v-model="data.isMultipleDate"
                class="tes2"
                white-space="nowrap"
              >
                Pilih beberapa tanggal sekaligus
              </c-checkbox>
              <c-box
                v-if="data.isMultipleDate && data.dateArray?.length >= 1"
                background-color="primary.50"
                padding-inline="12px"
                padding-block="6px"
                border-radius="12px"
                font-size="14px"
              >
                Tanggal terpilih: {{ formatDates(data.dateArray?.map((v) => v.dates) || []) }}
              </c-box>
            </c-flex>

            <c-box
              width="100%"
              margin-top="8px"
            >
              <VCalendar
                v-if="data.isMultipleDate"
                :attributes="data.dateArray"
                :columns="2"
                :is-expanded="true"
                mode="date"
                @dayclick="(el) => {
                  onDayClick(el)
                }"
              />
              <!-- "JSON.stringify(data.dateObject)" is to force re-rendering -->
              <!-- because if the change is from DatePicker, this component (VDatePicker) value not updating -->
              <!--              <VDatePicker-->
              <!--                v-else-->
              <!--                :key="JSON.stringify(data.dateObject)"-->
              <!--                v-model="data.dateObject"-->
              <!--                is-range-->
              <!--                :columns="2"-->
              <!--                :is-expanded="true"-->
              <!--                mode="date"-->
              <!--                color="green"-->
              <!--                :style="{-->
              <!--                  '&#45;&#45;green-200': '#C7F9E3',-->
              <!--                  '&#45;&#45;green-600': '#008C81',-->
              <!--                }"-->
              <!--              />-->
              <c-text
                v-if="!!validations?.error?.format()?.dateArray"
                color="red"
                margin-top="8px"
              >
                {{ validations?.error?.format()?.dateArray?.dates?._errors?.[0] }}
              </c-text>
            </c-box>
          </c-box>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          width="100%"
          justify-content="center"
          gap="16px"
          padding="25px 20px"
        >
          <BaseButton
            width="100%"
            max-width="400px"
            size="medium"
            @click="setFilter"
            :disabled="!validations.success"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { Icon } from '@iconify/vue2'
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import _ from 'lodash'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { formatDate } from '@/utils/format-date'
import { z } from 'zod'

export default {
  name: 'ManagementClientFilterBtn',
  components: { BaseModal, BaseButton, Icon, DatePicker },
  props: ['isOpen'],
  data() {
    return {
      isLoading: false,
      data: {
        status: this.$route.query?.status || 'all',
        date: this.$route.query?.date || '',
        dateObject: {
          start: this.$route.query?.startDate ? new Date(this.$route.query?.startDate) : null,
          end: this.$route.query?.endDate ? new Date(this.$route.query?.endDate) : null,
        },
        nutritionist: this.$route.query?.nutritionist === '1',
        isMultipleDate: !!this.$route.query?.dateArray,
        dateArray: this.$route.query?.dateArray?.length ? this.$route.query?.dateArray?.split('|')?.map((v) => {
          return { dates: new Date(v), highlight: { fillMode: 'light', style: { backgroundColor: '#C7F9E3' } } }
        }) : [],
      },
    }
  },
  computed: {
    validations() {
      const MealPlanSchema = z.object({
        // status: z.string(),
        date: z.string(),
        dateObject: z.object({
          start: z.date().nullable(),
          end: z.date().nullable(),
        }),
        // nutritionist: z.boolean(),
        isMultipleDate: z.boolean().nullable(),
        dateArray: z.array(z.object({
          dates: z.date(),
          // highlight: z.object({
          //   fillMode: z.string(),
          //   style: z.object({
          //     backgroundColor: z.string(),
          //   }),
          // }),
        })),
      }).superRefine((data, ctx) => {
        if (data.date && data.isMultipleDate) {
          if (data.dateArray?.length <= 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['dateArray', 'dates'],
              message: 'Please select at least one date',
            })
          }
        }

        if (data.date && !data.isMultipleDate) {
          if (!data.dateObject?.start) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['dateObject', 'start'],
              message: 'Please complete start date',
            })
          }
          if (!data.dateObject?.end) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['dateObject', 'end'],
              message: 'Please complete end date',
            })
          }
        }

        if (data.dateObject?.start && !data.dateObject?.end) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['dateObject', 'end'],
            message: 'Please complete end date',
          })
        }

        if (data.dateObject?.end && !data.dateObject?.start) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['dateObject', 'start'],
            message: 'Please complete start date',
          })
        }

        if (data.dateObject?.start && data.dateObject?.end) {
          if (!data.date && !data.isMultipleDate) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['date'],
              message: 'Please select date',
            })
          }
        }

        if (data.dateArray?.length >= 1) {
          if (!data.date && data.isMultipleDate) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['date'],
              message: 'Please select date',
            })
          }
        }

        if (data.dateArray?.length > 10) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['dateArray', 'dates'],
            message: 'Selected dates maximum is 10',
          })
        }
      })
      return MealPlanSchema.safeParse(this.data)
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(value) {
        this.data.status = value.status || 'all'
        this.data.date = value.date || ''
        this.data.dateObject.start = value.startDate ? new Date(value.startDate) : null
        this.data.dateObject.end = value.endDate ? new Date(value.endDate) : null
        this.data.nutritionist = value.nutritionist === '1'
        this.data.isMultipleDate = !!value.dateArray
        this.data.dateArray = value.dateArray?.length ? value.dateArray?.split('|')?.map((v) => {
          return { dates: new Date(v), highlight: { fillMode: 'light', style: { backgroundColor: '#C7F9E3' } } }
        }) : []
      },
    },
    'data.dateObject': {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value.start || !value.end)
          return
        if (value.start > value.end) {
          this.data.dateObject = {
            start: value.end,
            end: value.start,
          }
        }
      },
    },
  },
  methods: {
    setFilter() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: 1,
          status: this.data.status === 'all' ? '' : this.data.status,
          nutritionist: this.data.nutritionist ? '1' : '0',
          date: this.data.date,
          startDate: !this.data.isMultipleDate && this.data.dateObject?.start && formatDate(this.data.dateObject?.start, 'MM-DD-YYYY') || '',
          endDate: !this.data.isMultipleDate && this.data.dateObject?.end && formatDate(this.data.dateObject?.end, 'MM-DD-YYYY') || '',
          dateArray: this.data.isMultipleDate && this.data.dateArray?.length && this.data.dateArray?.map((v) => formatDate(v.dates, 'MM-DD-YYYY'))?.join('|') || '',
        },
        params: { savePosition: true },
      })
      this.$emit('close', false)
    },
    reset() {
      this.data.status = 'all'
      this.data.date = ''
      this.data.dateObject.start = null
      this.data.dateObject.end = null
      this.data.nutritionist = false
      this.data.isMultipleDate = false
      this.data.dateArray = []
    },
    onDayClick(tes) {
      const findDay = this.data.dateArray.find((v) => v.dates.toDateString() === tes.date.toDateString())
      if (findDay) {
        this.data.dateArray = this.data.dateArray.filter((v) => v.dates.toDateString() !== tes.date.toDateString())
      } else {
        this.data.dateArray.push({ dates: tes.date, highlight: { fillMode: 'light', style: { backgroundColor: '#C7F9E3' } } })
      }
    },
    formatDates(dates) {
      // Group dates by month and year
      const grouped = dates.reduce((acc, dateStr) => {
        const date = new Date(dateStr)
        const key = `${date.getMonth()}-${date.getFullYear()}`

        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(date.getDate())
        return acc
      }, {})

      // Format each group and join them
      const result = Object.entries(grouped).map(([key, days]) => {
        const [month, year] = key.split('-')
        const monthName = new Date(year, month).toLocaleString('en-US', { month: 'short' })
        return `${days.sort((a, b) => a - b).join(', ')} ${monthName} ${year}`
      }).join(' | ')

      return result
    },
  },
}
</script>

<style scoped>
::v-deep .tes [data-chakra-component="CRadio"]  {
  height: 40px;
}
::v-deep .tes [data-chakra-component="CRadio"] [data-chakra-component="CBox"] {
  font-size: 16px;
}
::v-deep .tes [data-chakra-component="CRadio"] [data-chakra-component="CControlBox"] {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: #333333;
}
::v-deep .tes [data-chakra-component="CRadio"] input[type=radio]:checked + [data-chakra-component="CControlBox"] {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: #008C81;
  background: white;
}
::v-deep .tes [data-chakra-component="CRadio"] input[type=radio]:checked + [data-chakra-component="CControlBox"] span {
  background: #008C81;
}

::v-deep .tes2  {
  height: 40px;
}
::v-deep .tes2 [data-chakra-component="CBox"] {
  font-size: 16px;
}
::v-deep .tes2 [data-chakra-component="CControlBox"] {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: #333333;
  border-radius: 4px;
}
::v-deep .tes2 input[type=checkbox]:checked + [data-chakra-component="CControlBox"] {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: black;
  background: #008C81;
}
::v-deep .tes2 input[type=checkbox]:checked + [data-chakra-component="CControlBox"] [data-chakra-component="CIcon"] {
  height: 17px !important;
  width: 17px !important;
}

::v-deep .tes3 [data-chakra-component="CSelectInput"] {
  height: 62px;
  font-size: 18px;
  border-radius: 8px;
  border-width: 2px;
  font-weight: 500;
  border-color: #888888;
  color: #888888;
  box-shadow: none;
}
::v-deep .tes3 [data-chakra-component="CSelectInput"]:not([value=""]) {
  border-color: #008C81;
  color: #008C81;
}
::v-deep .tes3 [data-chakra-component="CSelectInput"][aria-invalid=true] {
  border-color: red;
}

::v-deep .tes4-2 {
  width: 100%;
}
::v-deep .tes4-2 .mx-input-wrapper {
  width: 100%;
}
::v-deep .tes4-2 .mx-input-wrapper input {
  height: 62px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-color: #888888;
  color: #888888;
  border-width: 2px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
}
::v-deep .tes4-2.active .mx-input-wrapper input {
  height: 62px;
  border-color: #008C81;
  color: #008C81;
  border-width: 2px;
  width: 100%;
}
::v-deep .tes4-2 .mx-input-wrapper .mx-icon-calendar {
  opacity: 0;
}
::v-deep .tes4-2.invalid .mx-input-wrapper input {
  border-color: red;
  color: red;
}

::v-deep .vc-pane-layout .vc-pane:not(:last-child) {
  position: relative;
  border-right: 1px solid transparent;
}
::v-deep .vc-pane-layout .vc-pane:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -1px; /* Slightly larger than shadow spread */
  width: 1px;
  height: 90%; /* Covers top 25% */
  transform: translateY(-50%);
  background: #cdd1d7; /* Same as background color */
}
::v-deep .vc-header {
  padding-bottom: 8px;
}
::v-deep .vc-arrows-container [role="button"] {
  border: 1px solid #cdd1d7;
}
</style>
