<template>
  <ManagementClientsIndex
    :clients="clients"
    :export-url="exportUrl"
    :filters="statuses"
    :role="authRole"
    :is-loading="isLoadingClients"
    @update:persize="onChangePagesize"
    @update:sort="onChangeSort"
  />
</template>

<script>
import ManagementClientsIndex from '@/components/managements/clients'
import equal from 'fast-deep-equal'
import { formatDate, formatDateV2 } from '@/utils/format-date'

export default {
  name: 'ManagementAdminPage',
  components: { ManagementClientsIndex },
  data() {
    return {
      data: 'Management Client',
      statuses: [
        { label: 'Semua', value: 'all' },
        { label: 'Aktif', value: 'active' },
        { label: 'Tidak Aktif', value: 'non_active' },
        { label: 'Pending', value: 'pending' },
      ],
      clients: {
        columns: [
          {
            id: 'no',
            label: 'NO',
            type: 'AUTO_INCREMENT',
            align: 'center',
            itemAlign: 'center',
            width: '20px',
            frozen: true,
          },
          {
            id: 'client',
            label: 'NAMA',
            type: 'TEXT',
            width: '130px',
            sortable: true,
            customRender: (item) => {
              return `${item?.firstName} ${item?.lastName}`
            },
            frozen: true,
          },
          {
            id: 'phone',
            label: 'NO HP',
            type: 'TEXT',
            width: '120px',
            customRender: (item) => {
              return item?.phone || '-'
            },
          },
          {
            id: 'email',
            label: 'EMAIL',
            type: 'TEXT',
            customRender: (item) => {
              return item?.email || '-'
            },
          },
          {
            id: 'nutritionist',
            label: 'AHLI GIZI',
            type: 'TEXT',
            width: '130px',
            sortable: true,
            customRender: (item) => {
              return item?.programs?.nutritionist?.firstName ? `${item?.programs?.nutritionist?.firstName} ${item?.programs?.nutritionist?.lastName}` : '-'
            },
          },
          {
            id: 'startat',
            label: 'START',
            type: 'TEXT',
            width: '80px',
            sortable: true,
            customRender: (item) => {
              return item?.programs?.startAt ? `${formatDateV2(item?.programs?.startAt, 9)}` : '-'
            },
          },
          {
            id: 'endat',
            label: 'END',
            type: 'TEXT',
            width: '80px',
            sortable: true,
            customRender: (item) => {
              return item?.programs?.endAt ? `${formatDateV2(item?.programs?.endAt, 9)}` : '-'
            },
          },
          {
            id: 'program',
            label: 'PROGRAM',
            type: 'CUSTOM2',
            width: '130px',
            sortable: true,
          },
          {
            id: 'duration',
            label: 'DURASI PROGRAM',
            type: 'TEXT',
            sortable: true,
            customRender: (item) => {
              return item?.programs?.duration ? `${item?.programs?.duration} Hari` : '-'
            },
          },
          {
            id: 'status',
            label: 'STATUS',
            type: 'CUSTOM2',
            width: '105px',
          },
          {
            id: 'aksi',
            label: 'AKSI',
            type: 'CUSTOM2',
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 10,
          sort: {},
        },
        total: 0,
      },
      isLoadingClients: false,
    }
  },
  computed: {
    page() {
      return +(this.$route.query.page ?? 1)
    },
    search() {
      return this.$route.query.q
    },
    rows() {
      return this.$route.query.rows ?? '10'
    },
    status() {
      return this.$route.query.status
    },
    orderBy() {
      return this.$route.query.orderBy
    },
    order() {
      return this.$route.query.order
    },
    filters() {
      return {
        page: this.page,
        perPage: this.rows,
        search: this.search,
        status: this.status,
        orderBy: this.orderBy,
        order: this.order,
        nutritionist: this.$route.query?.nutritionist,
        date: this.$route.query?.date,
        startDate: this.$route.query?.startDate && formatDate(new Date(this.$route.query?.startDate), 'YYYY-MM-DD') || '',
        endDate: this.$route.query?.endDate && formatDate(new Date(this.$route.query?.endDate), 'YYYY-MM-DD') || '',
        dateArray: this.$route.query?.dateArray?.length ? JSON.stringify(this.$route.query?.dateArray?.split('|')?.map((v)=>formatDate(new Date(v), 'YYYY-MM-DD'))) : '',
      }
    },
    exportUrl() {
      let baseUrl = this.$store.getters.axios.defaults.baseURL
      let token = this.$store.state.auth.token
      let params = new URLSearchParams()
      params.set('token', token)
      if (this.filters.status != null)
        params.set('status', this.filters.status)

      return `${baseUrl}/v1/super-admin/clients/export?${params.toString()}`
    },
  },
  watch: {
    filters: {
      immediate: true,
      async handler(filters, old) {
        if (equal(filters, old)) return

        this.isLoadingClients = true
        const res = await this.$store.dispatch('admClients/listClients', filters)
        this.isLoadingClients = false
        this.clients.items = res.data
        this.clients.meta.page = res.meta?.page
        this.clients.meta.pagesize = res.meta?.pagesize
        this.clients.total = res.meta?.total
      },
    },
  },
  methods: {
    onChangePagesize(rows) {
      this.clients.meta.pagesize = rows
    },
    onChangeSort(sort) {
      this.clients.meta.sort = sort
    },
  },
}
</script>
