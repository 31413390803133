<template>
  <c-box
    v-dragscroll
    position="relative"
    width="100%"
    :height="['40px','50px']"
    overflow-x="hidden"
  >
    <c-flex
      :justify-content="justifyContent"
      :grid-gap="['4px', '8px']"
      h="auto"
      :padding-inline="['4px', '8px']"
      padding-block="4px"
      position="absolute"
    >
      <c-button
        v-for="option in options"
        :key="option.id"
        type="button"
        :h="['30px', '40px']"
        :px="['8px', '16px']"
        :min-w="minWidth"
        m="0"
        :font-size="['12px', '16px']"
        line-height="24px"
        rounded="full"
        color="primary.400"
        :bg=" 'white'"
        border-width="1px"
        border-style="solid"
        border-color="primary.400"
        white-space="nowrap"
        font-weight="400"
        flex-shrink="0"
        @click.stop.prevent="onChangeFilter(option)"
      >
        {{ option?.label }}
        <Icon
          icon="ic:sharp-close"
          height="25"
          width="25"
          style="margin-left: 6px"
        />
      </c-button>
    </c-flex>
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import { Icon } from '@iconify/vue2'

export default {
  name: 'ChipFilterClose',
  components: { Icon },
  directives: {
    dragscroll,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      // Example:
      // [
      //   { id: 1, label: 'A', disabled: false },
      //   { id: 2, label: 'B', disabled: true },
      // ]
      required: true,
    },
    selectedFilter: {
      type: Object,
      default: () => {},
    },
    justifyContent: {
      type: String,
      default: 'flex-start',
    },
    minWidth: {
      type: [String, Array],
      default: () => ['30px', '40px'],
    },
  },
  emits: ['on-change-filter'],
  methods: {
    onChangeFilter(char) {
      this.$emit('on-change-filter', char)
    },
  },
}
</script>
